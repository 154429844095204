<div class="row my-3" >
    <div class="user-name"> <h3 class="u-name text-jc"> {{ hideAdd ? 'Jobs' : 'Latest Jobs'}}  </h3></div>
    <div class="col-lg-12" *ngIf="hideAdd">
        <div class="row">
            <div class="col-md-3">
                <div class="card wizards wizards-viewers active-jobpost">
                    <div class="row">
                        <div class="col-12 wizards-image text-center">
                            <span class="wizards-title d-block">Latest jobs</span>
                        </div>
                        <div class="col-12 d-flex justify-content-between">
                            <span class="wizards-image job-posting"><img
                                    src="assets/images/Active-Job-Post-dashboard.png" class="" alt="..."></span>
                            <span class="wizards-count">{{ getAllJobDetailsdata?.latestJobs }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card wizards wizards-applicats clients">
                    <div class="row">
                        <div class="col-12 wizards-image text-center">
                            <span class="wizards-title d-block">Open Positions</span>
                        </div>
                        <div class="col-12 d-flex justify-content-between">
                            <span class="wizards-image job-posting"><img src="assets/images/clients-dashboard.png"
                                    class="" alt="..."></span>
                            <span class="wizards-count">{{ getAllJobDetailsdata?.openPositions }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card wizards wizards-applications vendors">
                    <div class="row">
                        <div class="col-12 wizards-image text-center">
                            <span class="wizards-title d-block">Closed Positions</span>
                        </div>
                        <div class="col-12 d-flex justify-content-between">
                            <span class="wizards-image job-posting"><img src="assets/images/vendors-dashboard.png"
                                    class="" alt="..."></span>
                            <span class="wizards-count">{{ getAllJobDetailsdata?.closedPositions }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card wizards wizards-employars vendors">
                    <div class="row">
                        <div class="col-12 wizards-image text-center">
                            <span class="wizards-title d-block">Applications</span>
                        </div>
                        <div class="col-12 d-flex justify-content-between">
                            <span class="wizards-image job-posting"><img src="assets/images/vendors-dashboard.png"
                                    class="" alt="..."></span>
                            <span class="wizards-count">{{ getAllJobDetailsdata?.totalApplications }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-lg-12" *ngIf="tableData">
        <div class="card">
            <app-table class="th-first-col" [tableData]="tableData" (onDropdownClickEmit)="updateJobStatus($event)"
                 (onViewRowEmit)="onViewRowEmit($event)" (onEditRowEmit)="onEditRowEmit($event)"
                [showCheckAll]="false">
                <div button *ngIf="hideAdd">
                    <button class="post-job-btn d-flex" (click)="addJob()">
                         Add Job
                    </button>
                </div>
            </app-table>
        </div>
    </div>
</div>