import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jobclicks-adds',
  templateUrl: './jobclicks-adds.component.html',
  styleUrls: ['./jobclicks-adds.component.scss']
})
export class JobclicksAddsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
