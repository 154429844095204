import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../core/services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../../../core/services/app.service';
import { SpinnerService } from '../../../core/services/spinner.service';
import { HttpMethod } from '../../../core/enums/http-handlers';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  // forgot form model
  forgotForm: FormGroup;

  // to show input error messgae
  submitted = false;

   // show server error
   showServerErrors =  {
    email: ''
  }

  constructor(
    private fb: FormBuilder,
    public commonService: CommonService,
    private appService: AppService,
    private spinnerService: SpinnerService,
    private route: Router,

  ) { }

  ngOnInit(): void {
    this.forgotFormModel();
    this.forgotForm.get('Email').valueChanges.subscribe(res => {
      this.showServerErrors ? this.showServerErrors.email = '': null
    })
  }

  forgotFormModel() {

    this.forgotForm = this.fb.group({
      Email: ['', Validators.compose([Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$"),])],
    });

  }

  // employee forgot password email
  onforgotpassword() {
    this.submitted = true;
    if (this.forgotForm.invalid) {
      return;
    }
    const url = `${this.appService.adminForgotPassword}?email=${this.forgotForm.get('Email').value}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.POST
    }
    this.commonService.commonApiCall(apiObj, ((res, statusFlag) => {
      this.spinnerService.hide();
      if(statusFlag === 'showControlError') {
        this.showServerErrors = res.data;
      } else if (statusFlag && res) {
        this.route.navigateByUrl('login')
      }
    }))
  }
  
  onCancel() {
    this.route.navigateByUrl('login')
  }

}

