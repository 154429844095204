<div class="container-fluid tech-news p-relative">
    <div class="row">
        <div class="col login-content">
            <div class="logo-section">
                <div class="logo-text">JOB Clicks</div>
                <div class="logo-sub-text">Welcome to Lorem ipsum is placeholder text</div>
            </div>
            <div class="login-page mt-3">
                <form [formGroup]="forgotForm">
                    <div class="login-title">
                        Forgot Password
                        <p>Enter the email associated with your account and we'll send an email with instructions to reset your password.</p>
                    </div>
                    <div class="forms mt-2">
                        <div class="form-group popup-controls mb-3">
                            <label> Enter address<span class="text-danger">*</span></label>
                            <input type="text" id="forgotEmail" class="form-control" formControlName="Email"
                                placeholder="Email Address" [ngClass]="{ 'is-invalid': 
                                (forgotForm.controls.Email.dirty && forgotForm.controls.Email.touched && forgotForm.controls.Email.errors) ||
                                (submitted && forgotForm.controls.Email.errors) ||
                                (showServerErrors && showServerErrors?.email)
                             }">
                            <div class="error_text mt-0" *ngIf="(forgotForm.controls.Email.dirty && forgotForm.controls.Email.touched && forgotForm.controls.Email.errors) ||
                            (submitted && forgotForm.controls.Email.errors)">
                                <div class="error_text mt-0"
                                    *ngIf="forgotForm.controls.Email.errors && forgotForm.controls.Email.errors.pattern">
                                    Please enter a valid Email. </div>
                                <div class="error_text mt-0 "
                                    *ngIf="forgotForm.controls.Email.errors && forgotForm.controls.Email.errors.required">
                                    Email is required.
                                </div>
                            </div>
                            <div class="error_text mt-0" *ngIf="showServerErrors && showServerErrors?.email">{{
                                showServerErrors.email }} </div>
                        </div>
                        <div class="login-button mt-4 text-center">
                            <button class="jc-button " (click)="onforgotpassword()">Submit</button>
                        </div>
                        <div class="login-button mt-4 text-center">
                            <button class="jc-btn-cancel" (click)="onCancel()">Cancel</button>
                        </div>
                    </div>

                </form>

            </div>
        </div>
    </div>
</div>