import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpMethod } from '../../../core/enums/http-handlers';
import { AppService } from '../../../core/services/app.service';
import { SuperAdminService } from '../../../core/services/super-admin.service';
import { CommonService } from '../../../core/services/common.service';
import { SpinnerService } from '../../../core/services/spinner.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-jobs-table',
  templateUrl: './jobs-table.component.html',
  styleUrls: ['./jobs-table.component.scss']
})
export class JobsTableComponent implements OnInit {

  @Input() hideAdd = true;

  tableData: any;
  getAllJobDetailsdata: any;

  constructor(
    private appService: AppService,
    private commonService: CommonService,
    private router: Router,
    private spinnerService: SpinnerService,
    public superAdminService: SuperAdminService,
    public datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.superAdminService.getUserAndJobStatusCodes((res) => {
      if (res) {
        this.getAllJobDetails();
      }
    })
  }

  getAllJobDetails() {
    const url = `${this.appService.getAllJobDetails}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET
    }
    this.commonService.commonApiCall(apiObj, ((res, statusFlag) => {
      this.spinnerService.hide();
      if (statusFlag && res && res.hasOwnProperty('data') && res.data && res.data.hasOwnProperty('jobDetails') && res.data.jobDetails.length) {
        this.getAllJobDetailsdata = res.data;
        this.formTable(res.data.jobDetails)
      }
    }));
  }

  formTable(data = []) {
    if (data && data.length) {
      data.map(res => {
        res.statusFilter = this.displayDropdown(res.statusId)
        res.dateFilter = this.datepipe.transform(res.postedDate, 'MM/dd/yyyy');
      })
    }
    this.tableData = {
      list: data,
      columns: [
        { key: 'companyName', label: 'Company Name ' },
        { key: 'jobTitle', label: 'Job Title' },
        { key: 'clientName', label: 'Client Name' },
        { key: 'employmentType', label: 'Employment Type' },
        { key: 'experience', label: 'Experience' },
        { key: 'totalPositions', label: 'No. of Positions' },
        { key: 'applicationReceivedCount', label: 'Applications' },
        { key: 'filledPositionCount', label: 'Filled' },
        { key: 'postedDate', label: 'Posted Date', format: 'date' },
        { key: 'statusId', label: 'Status', type: 'dropdown', lists: this.superAdminService.getUserAndJobStatusCodesData.jobstatusCodes, id: 'statusId', text: 'statusName' },
        { key: 'action', label: 'Action', type: 'action', view: true, edit: true }
      ],
      uniqueKey: 'jobId'
    }
  }


  displayDropdown(val) {
    const obj = this.superAdminService.getUserAndJobStatusCodesData.jobstatusCodes.find(res => res.statusId === val)
    return obj ? obj.statusName : ''
  }

  updateJobStatus(event, flag?) {
    const url = `${this.appService.updateJobStatus}`;
    const obj = { jobId: event.element.jobId, statusID: event.value }
    const apiObj = {
      url: url,
      methodType: HttpMethod.PUT,
      requestObj: obj
    }
    this.commonService.commonApiCall(apiObj, ((res, statusFlag) => {
      this.spinnerService.hide();
      if (statusFlag) {
        if (flag) {
          this.getAllJobDetails();
        }
      } else {
        this.getAllJobDetails();
      }
    }));
  }


  onViewRowEmit(event) {
    this.router.navigate(['/auth/jobs/job-view', event.jobId]);
  }
  

  onEditRowEmit(event) {
    this.router.navigate(['/auth/jobs/job-edit', event.jobId]);
  }
  
  addJob() {
    this.router.navigate(['/auth/jobs/job-add']);
  }

}
