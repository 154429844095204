import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { JobclicksAddsComponent } from './components/jobclicks-adds/jobclicks-adds.component';
import { UploadResumeComponent } from './components/upload-resume/upload-resume.component';
import { TableComponent } from './components/table/table.component';
import { SharedImportModule } from '../shared-import';
import { YesOrNoComponent } from './components/yes-or-no/yes-or-no.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { AutocompleteChipComponent } from './components/autocomplete-chip/autocomplete-chip.component';
import { AddsComponent } from './components/adds/adds.component';
import { ReminderPopupComponent } from './components/reminder-popup/reminder-popup.component';
// import { HttpClientModule } from '@angular/common/http'
// import { CarouselModule } from 'ngx-owl-carousel-o';

import { PhonePipe } from './pipe/phone.pipe';
import { CommonTablePopupComponent } from './components/common-table-popup/common-table-popup.component';
import { ApplicantTableComponent } from './components/applicant-table/applicant-table.component';
import { JobsTableComponent } from './components/jobs-table/jobs-table.component';

@NgModule({
  declarations: [FileUploadComponent, JobclicksAddsComponent, UploadResumeComponent, TableComponent,
    YesOrNoComponent, ToasterComponent, AutocompleteComponent, AutocompleteChipComponent, AddsComponent,
    ReminderPopupComponent, PhonePipe, CommonTablePopupComponent, ApplicantTableComponent, JobsTableComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedImportModule
    // CarouselModule
  ],
  exports: [FormsModule, ReactiveFormsModule, FileUploadComponent, JobclicksAddsComponent,
    UploadResumeComponent, TableComponent, YesOrNoComponent, ToasterComponent, AutocompleteComponent,
    AutocompleteChipComponent, AddsComponent, ReminderPopupComponent, PhonePipe, CommonTablePopupComponent, ApplicantTableComponent, JobsTableComponent// CarouselModule
  ],
  providers: [DatePipe]
})
export class SharedModule { }
