<div class="container aling-item-center p-relative">
    <span class="close-button" (click)="dialogRef.close()"><img src="assets/images/close.png" class="close-popup"
            alt="logo"></span>


    <form [formGroup]="reminderForm">
        <div class="title-small text-center">
            Set Remainder
        </div>
        <mat-form-field class="remainder-date-picker" [floatLabel]="'never'">
            <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a date & time" formControlName="date"
                [min]="minDate" [ngClass]="{ 'is-invalid': 
             (reminderForm.controls.date.dirty && reminderForm.controls.date.touched && reminderForm.controls.date.errors) ||
             (submitted && reminderForm.controls.date.errors)
         }">
            <mat-datepicker-toggle class="remainder-date" matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker class="remainder-time" #picker></ngx-mat-datetime-picker>
        </mat-form-field>
        <div class="error_text mt-0" *ngIf="submitted && reminderForm.controls.date.invalid">
            <div class="error_text mt-0" *ngIf="reminderForm.controls.date.errors.required">
                Please select date and time
            </div>
        </div>

        <textarea type="text" class="form-control reminder-area" formControlName="notes"></textarea>

        <div class="reminder-buttons d-flex justify-content-center mt-3">
            <button class="jc-button btn-sm d-block mx-2" (click)="saveReminder()">Save</button>
            <button class="jc-button jc-btn-cancel btn-sm d-block mx-2" (click)="dialogRef.close()">Cancel</button>
        </div>

    </form>

</div>