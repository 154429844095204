import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-yes-or-no',
  templateUrl: './yes-or-no.component.html',
  styleUrls: ['./yes-or-no.component.scss']
})
export class YesOrNoComponent implements OnInit {

  showComment = false;

  constructor(
    public dialogRef: MatDialogRef<YesOrNoComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { 
    if(data.hasOwnProperty('comment')) {
      this.showComment = true;
    }
  }

  ngOnInit(): void {
  }

  yes() {
    if(this.showComment) {
      this.dialogRef.close(this.data)
    } else {
      this.dialogRef.close(true)
    }
  }

}
