import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-common-table-popup',
  templateUrl: './common-table-popup.component.html',
  styleUrls: ['./common-table-popup.component.scss']
})
export class CommonTablePopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CommonTablePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
  }

}
