<div class="mb-3" *ngIf="showHeader">
    <div class="row">
        <div class="col-lg-12 d-flex">
            <div class="manage-job-header d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                    <div class="form-check mx-2" *ngIf="showCheckAll">
                        <input type="checkbox" class="form-check-input" [checked]="allChecked" id="exampleCheck"
                            (click)="checkAll($event)">
                    </div>
                    <ng-content select="[custom]"></ng-content>
                </div>
                    <div class="grid-search d-flex">
                        <input type="text" class="form-control" [(ngModel)]="filterTable" (keyup)="doFilter($event)"
                            placeholder="Search">
                        <img src="assets/images/search.svg" class="d-block mr-2 search-img" alt="...">
                    </div>
            </div>
            <ng-content select="[button]"></ng-content>
        </div>
    </div>
</div>
<div class="" *ngIf="showShearchHeader">
    <div class="row">
        <div class="col-lg-12 d-flex align-items-center">
            <div class="reports-header d-flex justify-content-between align-items-center">
                <span class="text-jc" *ngIf="shearchHeader"> {{ shearchHeader }} </span>
                <div class="grid-search d-flex">
                    <input type="text" class="form-control" [(ngModel)]="filterTable" (keyup)="doFilter($event)"
                        placeholder="Search">
                    <img src="assets/images/search.svg" class="d-block mr-2 search-img" alt="...">
                </div>
            </div>
            <ng-content select="[dropdown]"></ng-content>
        </div>
    </div>
</div>
<mat-card class="manage-job-grid mb-3">
    <mat-card-content>
        <div *ngIf="!(dataSource && dataSource.data && dataSource.data.length)"
            class="d-flex justify-content-center align-items-center flex-column">
            <img class="text-bottom" width="300" height="200" src="assets/images/nodatafound.png">
            <h5 class="no_data_found">No Data Found</h5>
        </div>
        <div class="table" [hidden]="!(dataSource && dataSource.data.length)">

            <table class="w-100" mat-table [dataSource]="dataSource" matSort #mytable>
                <ng-container *ngFor="let column of getDisplayedColumns()" [matColumnDef]="column">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        [ngClass]="[ (displayLable(column).type == 'checkbox') ? 'checkbox-width' : '' , displayLable(column).class ? displayLable(column).class : '' ]">
                        <strong> {{ displayLable(column).label }} </strong>
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                        <div [ngSwitch]="displayLable(column).type"
                            [ngClass]="{ 'text-ellipsis' : displayLable(column).dots }">
                            <div *ngSwitchDefault
                                [ngClass]="[  displayLable(column).class ? displayLable(column).class : '', (displayLable(column).class == 'custom-status') ? element[column] : '', showMatToolip(element[column]) ? 'showTooltip' : '' ]"
                                [matTooltip]="showMatToolip(element[column])"> {{ displayLable(column).format == 'date'
                                ? (element[column] | date: 'MM/dd/yyyy' ) : element[column] }} </div>
                            <div *ngSwitchCase="'sno'">{{ i + 1 }}</div>
                            <div *ngSwitchCase="'link'">
                                <span class="hyperlink cursor-pointer"
                                    [ngClass]="[ displayLable(column).class ? displayLable(column).class : '', showMatToolip(element[column]) ? 'showTooltip' : '' ]"
                                    [matTooltip]="showMatToolip(element[column])" (click)="onLink(element, column)">
                                    {{ element[column] }} </span>
                            </div>
                            <div *ngSwitchCase="'text'">
                                <span class="grid-text"
                                    [ngClass]="[ displayLable(column).class ? displayLable(column).class : '', showMatToolip(element[column]) ? 'showTooltip' : '' ]"
                                    [matTooltip]="showMatToolip(element[column])">
                                    {{ element[column] }} </span>
                            </div>
                            <div *ngSwitchCase="'phone'">
                                <span class="grid-text">
                                    {{ element[column] ? (element[column] | phone) : element[column] }} </span>
                            </div>
                            <div *ngSwitchCase="'checkbox'">
                                <span>
                                    <input type="checkbox" class="mr-3" [checked]="element.checked"
                                        (click)="checkboxClick($event, element)" />
                                </span>
                            </div>
                            <div *ngSwitchCase="'dropdown'">
                                <span>
                                    <div class="dropdown navbar py-1">
                                        <button class="btn btn-secondary dropdown-toggle custom-dropdown active"
                                            [ngClass]="displayDropdown(displayLable(column), element[column])"
                                            type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                            aria-expanded="false">
                                            {{ displayDropdown(displayLable(column), element[column]) }}
                                        </button>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li *ngFor="let list of displayLable(column).lists"><a class="dropdown-item"
                                                    (click)="dropdownClick(list[displayLable(column).id], column, element, list[displayLable(column).text])">
                                                    {{ list[displayLable(column).text] }}</a></li>
                                        </ul>
                                    </div>
                                </span>
                            </div>
                            <div *ngSwitchCase="'action'" class="text-center">
                                <span *ngIf="displayLable(column).mail">
                                    <img src="assets/images/email.svg" class="mr-2 cursor-p" alt="..."
                                        (click)="onMailRow(element)">
                                    {{ element[column] }}
                                </span>
                                <span *ngIf="displayLable(column).view">
                                    <img src="assets/images/blue-view.svg" class="mr-2 cursor-p" alt="..."
                                        (click)="onViewRow(element)">
                                    {{ element[column] }}
                                </span>
                                <span *ngIf="displayLable(column).edit && !element.hideEdit">
                                    <img src="assets/images/edit.svg" class="mr-2 cursor-p" alt="..."
                                        (click)="onEditRow(element)">
                                    {{ element[column] }}
                                </span>
                                <span *ngIf="displayLable(column).delete">
                                    <img src="assets/images/delete-red.svg" class="mr-2 cursor-p" alt="..."
                                        (click)="onDeleteRow(element)">
                                    {{ element[column] }}
                                </span>
                            </div>
                        </div>

                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
                <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();"></tr>
            </table>
        </div>
        <mat-paginator [pageSizeOptions]="[5,15 ,25, 50, 100]" showFirstLastButtons></mat-paginator>


    </mat-card-content>
</mat-card>