<div class="container-fluid tech-news p-relative" >
    <div class="row">
        <div class="col login-content">
            <div class="logo-section">
                <div class="logo-text">JOB Clicks</div>
                <div class="logo-sub-text">Welcome to Lorem ipsum is placeholder text</div>
            </div>
            <div class="login-page">
                <div class="login-title">
                    Login
                </div>
                <form [formGroup]="loginForm">

                    <div class="forms mt-3">

                        <div class="form-group popup-controls mb-3">
                            <label>Email ID<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" placeholder="Enter email" (change)="getPassword()"
                            (blur)="getPassword()"  formControlName="email"
                                [ngClass]="{ 'is-invalid': 
                                (loginForm.controls.email.dirty && loginForm.controls.email.touched && loginForm.controls.email.errors) ||
                                (submitted && loginForm.controls.email.errors) ||
                                (showServerErrors && showServerErrors?.email) 
                            }">
                            <div class="error_text mt-0" *ngIf="(loginForm.controls.email.dirty && loginForm.controls.email.touched && loginForm.controls.email.errors) ||
                            (submitted && loginForm.controls.email.errors)">
                                <div class="error_text mt-0" *ngIf="loginForm.controls.email.errors && loginForm.controls.email.errors.pattern">Please
                                    enter
                                    a
                                    valid Email.
                                </div>
                                <div class="error_text mt-0" *ngIf="loginForm.controls.email.errors && loginForm.controls.email.errors.required">Email is
                                    required.</div>
                                </div>
                                <div class="error_text mt-0" *ngIf="showServerErrors && showServerErrors?.email">{{ showServerErrors.email }} </div>
                        </div>

                        <div class="form-group popup-controls mb-2 position-relative">
                            <label>Password<span class="text-danger">*</span> <img src="assets/images/information.svg"
                                    matTooltip="Password must be a minimum of 8 characters with at least 1 special character($,@,!,&,%,#), 1 number(0-9), 1 uppercase(A-Z) and 1 lowercase character(a-z)."
                                    matTooltipPosition="right" class="ml-1 password-info" tooltip="Import File"
                                    alt="logo"></label>
                            <input [type]="hide ? 'password' : 'text'" class="form-control bg-img-none" placeholder="Enter password"
                                formControlName="password"
                                [ngClass]="{ 'is-invalid': 
                                (loginForm.controls.password.dirty && loginForm.controls.password.touched && loginForm.controls.password.errors) ||
                                (submitted && loginForm.controls.password.errors) ||
                                (showServerErrors && showServerErrors?.password)
                                }">
                            <i class="eye-icon" (click)="hide = !hide" [ngClass]="(hide)?'fa fa-eye-slash':'fa fa-eye'"
                                aria-hidden="true"></i>
                                <div class="error_text mt-0" *ngIf="(loginForm.controls.password.dirty && loginForm.controls.password.touched && loginForm.controls.password.errors) ||
                                (submitted && loginForm.controls.password.errors)">
                                    <div class="error_text mt-0" *ngIf="loginForm.controls.password.errors && loginForm.controls.password.errors.required">
                                        Password
                                        is
                                        required.</div>
                                    </div>
                                    <div class="error_text mt-0" *ngIf="loginForm.controls.password.errors && loginForm.controls.password.errors.cannotContainSpace">
                                        Password cannot contain space. </div>
                                    <div class="error_text mt-0" *ngIf="showServerErrors && showServerErrors?.password">{{ showServerErrors.password }} </div>
                        </div>

                        <div class="ml-14">
                            <span class="d-block forgot-psw cursor-p" (click)="showForgotPwdModel()">Forgot Password</span>
                        </div>

                        <div class="login-button mt-3 text-center">
                            <button class="jc-button " (click)="loginSubmit()">Login</button>
                        </div>

                    </div>
                </form>
            </div>

        </div>
    </div>
</div>

