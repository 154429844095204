import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppLayoutComponent } from './block/app-layout/app-layout.component';
import { ForgotPasswordComponent } from './block/app-layout/forgot-password/forgot-password.component';
import { LoginComponent } from './block/app-layout/login/login.component';
import { ResetPasswordComponent } from './block/app-layout/reset-password/reset-password.component';

const routes: Routes = [
   {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: '/login', pathMatch: 'full'
      },
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'set-password',
        component: ResetPasswordComponent
      },
      {
        path: 'forgotPassword',
        component: ForgotPasswordComponent
      }
    ]
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./feature/feature.module').then((m) => m.FeatureModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
