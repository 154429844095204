import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../../core/services/app.service';
import { SuperAdminService } from '../../../core/services/super-admin.service';
import { CommonService } from '../../../core/services/common.service';
import { SpinnerService } from '../../../core/services/spinner.service';
import { YesOrNoComponent } from '../../../shared/components/yes-or-no/yes-or-no.component';
import { HttpMethod } from '../../../core/enums/http-handlers';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-applicant-table',
  templateUrl: './applicant-table.component.html',
  styleUrls: ['./applicant-table.component.scss']
})
export class ApplicantTableComponent implements OnInit {

  @Input() hideAdd = true;
  
  tableData: any;

  selectedAll = [];

  showAddPostjob = true;
  showSatusPostJob = true;

  constructor(
    private appService: AppService,
    private commonService: CommonService,
    private router: Router,
    private spinnerService: SpinnerService,
    public superAdminService: SuperAdminService,
    public datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.superAdminService.getEmployeeStatuses((res) => {
      if (res) {
        this.getAllApplicantDetails();
      }
    })
  }

  getAllApplicantDetails() {
    this.selectedAll = [];
    const url = `${this.appService.getAllApplicantDetails}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET
    }
    this.commonService.commonApiCall(apiObj, ((res, statusFlag) => {
      this.spinnerService.hide();
      if (statusFlag && res && res.hasOwnProperty('data') && res.data.length) {
        this.formTable(res.data)
      }
    }));
  }

  displayDropdown(val) {
    const obj = this.superAdminService.getEmployeeStatusesData.find(res => res.statusId === val)
    return obj ? obj.statusName : ''
  }

  formTable(data = []) {
    if (data && data.length) {
      data.map(res => {
        res.statusFilter = this.displayDropdown(res.statusID),
        res.dateFilter = this.datepipe.transform(res.postedDate, 'MM/dd/yyyy');
      })
    }
    this.tableData = {
      list: data,
      columns: [
        { key: 'firstName', label: 'First Name ' },
        { key: 'lastName', label: 'Last Name' },
        { key: 'email', label: 'E-mail' },
        { key: 'mobileNo', label: 'Mobile', type: 'phone' },
        { key: 'applyedCount', label: 'Job Applied', class:'text-center' },
        { key: 'totalExperience', label: 'Experience' },
        { key: 'resume', label: 'Resume', class: 'status' },
        { key: 'createdOn', label: 'Register Date', format: 'date' },
        { key: 'statusID', label: 'Status', type: 'dropdown', lists: this.superAdminService.getEmployeeStatusesData, id: 'statusId', text: 'statusName' },
        { key: 'action', label: 'Action', type: 'action', edit: true, view: true }
      ],
      uniqueKey: 'userId'
    }
  }

  updateJobStatus(event, flag?) {
    const url = `${this.appService.updateEmployeeStatus}`;
    const obj = { userId: event.element.userId, statusId: event.value }
    const apiObj = {
      url: url,
      methodType: HttpMethod.PUT,
      requestObj: obj
    }
    this.commonService.commonApiCall(apiObj, ((res, statusFlag) => {
      this.spinnerService.hide();
      if (statusFlag) {
        if (flag) {
          this.getAllApplicantDetails();
        }
      } else {
        this.getAllApplicantDetails();
      }
    }));
  }

  onEditRowEmit(event) {
    this.router.navigate(['/auth/applicant/applicant-edit', event.userId]);
  }

  onViewRowEmit(event) {
    debugger
    this.router.navigate(['/auth/applicant/applicant-view', event.userId]);
  }

  addApplicant() {
    this.router.navigate(['/auth/applicant/applicant-add']);
  }

}