<div class="advertisement-section-title">
    Advertisement Section
  </div>
  <div class="card advertisements my-3">
    <div class="advertisements-body">
      <div class="row">
        <div class="col p-0">
          <div class="d-flex">
            <img src="assets/images/add-logo-1.png" class="add-logo" alt="...">
            <div class="pl-3">
              <span class="logo-title d-block">Google</span>
              <span class="add-tagline">
                Internet Mountain View
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="advertisements-footer d-flex justify-content-between">
      <span class="footer-text">Google-NY, USA</span>
      <button class="jc-add-button">View Profile</button>
    </div>
  </div>