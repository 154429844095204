import { Component } from '@angular/core';
import { SpinnerService } from './core/services/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from './core/services/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  showSpinner: boolean;

  constructor(public spinnerService: SpinnerService, private commonService : CommonService, private router: Router, private activatedRoute: ActivatedRoute ) { 

    // window.addEventListener('storage', () => {
    //   // When local storage changes, dump the list to
    //   // the console.
    //   this.checkLogin()
    // });
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    
    if(!loginData) {
      this.router.navigateByUrl('');
    }
  }

  checkLogin() {
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    if(loginData && loginData.id) {
        this.commonService.loginRoute();
    } else {
      this.router.navigateByUrl('');
    }
  }


}
