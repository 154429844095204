import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppLayoutComponent } from './app-layout/app-layout.component';
import { FooterComponent } from './footer/footer.component';
import { NavigationComponent } from './navigation/navigation.component';

import { SharedImportModule } from '../shared-import';
import { SharedModule } from '../shared/shared.module';
import { LoginComponent } from './app-layout/login/login.component';
import { ForgotPasswordComponent } from './app-layout/forgot-password/forgot-password.component';
import { AppRoutingModule } from '../app-routing.module';
import { ResetPasswordComponent } from './app-layout/reset-password/reset-password.component';

@NgModule({
  declarations: [
    AppLayoutComponent,
    FooterComponent,
    NavigationComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedImportModule,
    SharedModule,
    AppRoutingModule
  ],
  exports:[
    AppLayoutComponent,
    FooterComponent,
    NavigationComponent,
    ResetPasswordComponent,
    LoginComponent,
    ForgotPasswordComponent
  ]
})
export class BlockModule { }
