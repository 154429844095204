<div class="container-fluid tech-news p-relative">
    <div class="row">
        <div class="col my-3">
            <div class="login-page toster">
                <!-- <div class="logo text-center">
                    <img src="assets/images/logo.png" class="mb-3" alt="logo">
                    <p class="content-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                        has
                        been the industry's</p>
                </div> -->
                <div class="success-img text-center">
                    <img [src]="image" class="mr-2" alt="...">
                </div>
                <div class="text-center mt-3" [ngClass]="{ 'jc-text-success' : data.type == 'success', 'jc-text-danger' : data.type == 'error'  }">
                    {{ data.message }}
                </div>
            </div>
        </div>
    </div>
</div>