import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from '../../../core/services/common.service';
import { AppService } from '../../../core/services/app.service';
import { HttpMethod } from '../../../core/enums/http-handlers';
import { MustMatch } from '../../../core/services/confirmed.validator';
import { SpinnerService } from '../../../core/services/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WhiteSpaceValidator } from '../../../core/utilities/validation';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {


  // login form model
  setPasswordForm: FormGroup;

  // to show input error messgae
  submitted = false;

  // to hide and show password
  hide = true;

  constructor(
    private fb: FormBuilder,
    public commonService: CommonService,
    private appService: AppService,
    private router: ActivatedRoute,
    private route: Router,
    private spinnerService: SpinnerService
  ) { 
    this.setPasswordModel();
    this.router.queryParams.subscribe(res => {
      if(res && res.hasOwnProperty('param') && res.param) {
        this.setPasswordForm.patchValue(res);
      } else {
        this.route.navigateByUrl('/login')
      }
    })
  }

  ngOnInit(): void {
  }

  setPasswordModel() {
    //employee form validation methods
    this.setPasswordForm = this.fb.group({
      param: ['', Validators.required],
      password: ['', Validators.compose([Validators.required, Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,32}$"), WhiteSpaceValidator.cannotContainSpace])],
      confirmPassword: ['', Validators.required],
    },
      {
        validator: [MustMatch('password', 'confirmPassword')]
      });

  }

  submitForm() {
    this.submitted = true;
    if (this.setPasswordForm.invalid) {
      return;
    }
    const url = `${this.appService.adminResetPassword}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.POST,
      requestObj: this.setPasswordForm.value
    }
    this.commonService.commonApiCall(apiObj, ((res, statusFlag) => {
      this.spinnerService.hide();
      if (statusFlag && res && res.hasOwnProperty('data')) {
        this.route.navigateByUrl('/login')
      }
    }));
  }


}