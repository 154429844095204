<div class="card my-3">
    <!-- <div class="row mt-3">
        <div class="col-12 text-center">
            
        </div>
    </div> -->
    <div class="row">
        <div class="col-lg-12" *ngIf="tableData">
            <div class="title d-flex table-title">Applicant</div>
            <app-table class="th-first-col" [tableData]="tableData" (onDropdownClickEmit)="updateJobStatus($event)"
                (onEditRowEmit)="onEditRowEmit($event)"  (onViewRowEmit)="onViewRowEmit($event)" [showCheckAll]="false">
                <div button *ngIf="hideAdd">
                    <button class="post-job-btn d-flex" (click)="addApplicant()">
                         Add Applicant
                    </button>
                </div>
            </app-table>
        </div>
    </div>
</div>