import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor() { }


  domain = 'https://api2.job-clicks.com/';
  
  loginAdmin = `${this.domain}api/Login/Admin`;
  adminForgotPassword = `${this.domain}api/registration/AdminForgotPassword`;
  adminResetPassword = `${this.domain}api/registration/AdminResetPassword`;

  getAllApplicantDetails = `${this.domain}api/admin/GetAllApplicantDetails`;
  getAllEmployerDetails = `${this.domain}api/admin/GetAllEmployerDetails`;

  getUserAndJobStatusCodes = `${this.domain}api/employee/GetUserAndJobStatusCodes`;
  updateJobStatus = `${this.domain}api/employer/UpdateJobStatus`;
  getEmployerStatuses = `${this.domain}api/admin/GetEmployerStatuses`;
  getEmployeeStatuses = `${this.domain}api/admin/GetEmployeeStatuses`;

  getEmployeeDatabyUserID = `${this.domain}api/employee/GetEmployeeDatabyUserID`;
  updateEmployeeEmail = `${this.domain}api/employee/UpdateEmployeeEmail`;
  resendOTP = `${this.domain}api/registration/ResendOTP`;
  verifyEmployerEmail = `${this.domain}api/employer/VerifyEmployerEmail`;
  resendEmailVerification = `${this.domain}api/registration/ResendEmailVerification`;

  updateMobileNumber = `${this.domain}api/employee/UpdateMobileNumber`;
  getAllApplyedJobByEmployeeId = `${this.domain}api/admin/GetAllApplyedJobByEmployeeId`;

  saveEmployeeDetails = `${this.domain}api/admin/SaveEmployeeDetails`;
  saveEmployeeCertificationData = `${this.domain}api/employee/SaveEmployeeCertificationData`;
  getCitiesByStateID = `${this.domain}api/employee/GetCitiesByStateID`;
  getStatesByCountryID = `${this.domain}api/employee/GetStatesByCountryID`;
  getCountrys = `${this.domain}api/employee/GetCountrys`;
  getalldropdownlist = `${this.domain}api/employee/GetEmployeeProfileDropdownData`;
  removeEmployeeEducation = `${this.domain}api/employee/RemoveEmployeeEducation`;
  removeEmployeeSkill = `${this.domain}api/employee/RemoveEmployeeSkill`;
  removeEmployeeWorkExperience = `${this.domain}api/employee/RemoveEmployeeWorkExperience`;
  removeEmployeeCertification = `${this.domain}api/employee/RemoveEmployeeCertification`;
  updateEmployeedata = `${this.domain}api/employee/UpdateEmployeedata`;
  updateEmployeeJobInformationdata = `${this.domain}api/employee/UpdateEmployeeJobInformationdata`;
  saveEmployeeSkillsData = `${this.domain}api/employee/SaveEmployeeSkillsData`;
  saveEmployeeEducationData = `${this.domain}api/employee/SaveEmployeeEducationData`;
  saveEmployeeWorkExperienceData = `${this.domain}api/employee/SaveEmployeeWorkExperienceData`;
  updateEmployeeKnowOtherSkills = `${this.domain}api/employee/UpdateEmployeeKnowOtherSkills`;

  updateEmployeeFiles = `${this.domain}api/employee/UpdateEmployeeFiles`;
  removeEmployeeFiles = `${this.domain}api/employee/RemoveEmployeeFiles`;
  verifyEmployeeEmail = `${this.domain}api/employee/VerifyEmployeeEmail`;

  getAllJobTitles = `${this.domain}api/employee/GetAllJobTitles`;
  getEmployerDetails = `${this.domain}api/employer/GetEmployerDetails`;
  updateEmployerDetails = `${this.domain}api/employer/UpdateEmployerDetails`;
  updateEmployerContactPersonData = `${this.domain}api/employer/UpdateEmployerContactPersonData`;
  updateEmployerLogo = `${this.domain}api/employer/UpdateEmployerLogo`;
  saveEmployerDetails = `${this.domain}api/admin/SaveEmployerDetails`;

  updateEmployerMobileNumber = `${this.domain}api/employer/UpdateMobileNumber`;
  updateEmployerEmail = `${this.domain}api/employer/UpdateEmployerEmail`;
  getAllEmployerUsersData = `${this.domain}api/employer/GetAllEmployerUsersData`;
  getEmployeeSubUserDetailsBasedOnUserId = `${this.domain}api/employer/GetEmployeeSubUserDetailsBasedOnUserId`;
  getAllEmployerUserReport = `${this.domain}api/employer/GetAllEmployerUserReport`;
  getUsersPostJobGraphData = `${this.domain}api/employer/GetUsersPostJobGraphData`;
  getPostJobGraphDataByCustomDate = `${this.domain}api/employer/GetPostJobGraphDataByCustomDate`;
  getAllUserMassEmailinformation = `${this.domain}api/employer/GetAllUserMassEmailinformation`;

  getJobDetailsByJobId = `${this.domain}api/employer/GetJobDetailsByJobId`;
  getAllJobDetails = `${this.domain}api/admin/GetAllJobDetails`;
  getSuperAdminDashboardCount = `${this.domain}api/admin/GetSuperAdminDashboardCount`;
  getSuperAdminGraphData = `${this.domain}api/admin/GetSuperAdminGraphData`;
  getSuperAdminGraphDataByCustomDate = `${this.domain}api/admin/GetSuperAdminGraphDataByCustomDate`;
  getAllJobsBasedOnusaStates = `${this.domain}api/admin/GetAllJobsBasedOnusaStates`;
  removeEmployerLogo = `${this.domain}api/employer/RemoveEmployerLogo`;
  
  updateEmployerStatus = `${this.domain}api/admin/UpdateEmployerStatus`;
  updateEmployeeStatus = `${this.domain}api/admin/UpdateEmployeeStatus`;
  
  
  getAllBlogs = `${this.domain}api/admin/GetAllBlogs`;
  getBlogsMasterData = `${this.domain}api/admin/GetBlogsMasterData`;
  getAllBlogDetailsBasedOnBlogID = `${this.domain}api/admin/GetAllBlogDetailsBasedOnBlogID`;
  saveBlogs = `${this.domain}api/admin/SaveBlogs`;
  updateBlogs = `${this.domain}api/admin/UpdateBlogs`;
  deleteBlog = `${this.domain}api/admin/DeleteBlog`;
  getEmployerSummaryDetails = `${this.domain}api/admin/GetEmployerSummaryDetails`;
  updateBlogStatus = `${this.domain}api/admin/UpdateBlogStatus`;
  updateBlogImages = `${this.domain}api/admin/UpdateBlogImages`;
  
  
  saveUserDetails = `${this.domain}api/admin/SaveUserDetails`;
  getAllUserDetails = `${this.domain}api/admin/GetAllUserDetails`;
  getUserDetailsBasedOnUserId = `${this.domain}api/admin/GetUserDetailsBasedOnUserId`;
  getUserSummaryDetails = `${this.domain}api/admin/GetUserSummaryDetails`;
  updateUserDetails = `${this.domain}api/admin/UpdateUserDetails`;
  getAdminTypes = `${this.domain}api/admin/GetAdminTypes`;
  
  updateSubUserStatus = `${this.domain}api/employer/UpdateSubUserStatus`;
  
  getalldropdownlistpostjobs = `${this.domain}api/employer/GetEmployerPostJobsDropdownData`;
  getJobsummaryDetailsByJobID = `${this.domain}api/admin/GetJobsummaryDetailsByJobID`;
  saveJobThroughAdmin = `${this.domain}api/admin/SaveJobThroughAdmin`;
  getCompanydetailsByCompanyName = `${this.domain}api/admin/GetCompanydetailsByCompanyName`;
  getAllRegisteredCompanies = `${this.domain}api/admin/GetAllRegisteredCompanies`;
  updatejobdeatils = `${this.domain}api/employer/UpdateJobDetails`;
  updateJobCompanyDetails = `${this.domain}api/employer/UpdateJobCompanyDetails`;
  
  
  getAllEmployerUserResponsibilities = `${this.domain}api/employer/GetAllEmployerUserResponsibilities`;
  updateEmployerSubUsersResponsibilities = `${this.domain}api/employer/UpdateEmployerSubUsersResponsibilities`;
  updateEmployerSubUsersQuota = `${this.domain}api/employer/UpdateEmployerSubUsersQuota`;
  
  getAllAdminJobTitles = `${this.domain}api/admin/GetAllJobTitles`;
  saveAndUpdateJobTitle = `${this.domain}api/admin/SaveAndUpdateJobTitle`;
  updateJobTitleStatus = `${this.domain}api/admin/UpdateJobTitleStatus`;
  
  getAllIndustries = `${this.domain}api/admin/GetAllIndustries`;
  saveAndUpdateIndustries = `${this.domain}api/admin/SaveAndUpdateIndustries`;
  updateIndustryStatus = `${this.domain}api/admin/UpdateIndustryStatus`;
  getAllActiveIndustries = `${this.domain}api/employer/GetAllActiveIndustries`;
  deleteBlogImage = `${this.domain}api/admin/DeleteBlogImage`;


  getBlogCategories = `${this.domain}api/admin/GetBlogCategories`;
  getBlogCategoryBasedCategoryId = `${this.domain}api/admin/GetBlogCategoryBasedCategoryId`;
  saveBlogCategories = `${this.domain}api/admin/SaveBlogCategories`;
  updateBlogCategories = `${this.domain}api/admin/UpdateBlogCategories`;
  updateBlogCategoryStatus = `${this.domain}api/admin/UpdateBlogCategoryStatus`;

}
