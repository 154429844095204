import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from '../../../core/services/common.service';

@Component({
  selector: 'app-reminder-popup',
  templateUrl: './reminder-popup.component.html',
  styleUrls: ['./reminder-popup.component.scss']
})
export class ReminderPopupComponent implements OnInit {

  selectedMonth: Date;

  reminderForm: FormGroup;

  submitted = false;

  minDate = new Date();


  constructor(
    private fb: FormBuilder,
    public commonService: CommonService,
    public dialogRef: MatDialogRef<ReminderPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {

  }


  ngOnInit(): void {
    this.reminderForm = this.fb.group({
      date: [new Date(), Validators.required],
      notes: ['']
    });
    if (this.data != 'all' && this.data.reminderDate) {
      this.reminderForm.patchValue({
        date: this.data.reminderDate ? new Date(this.data.reminderDate) : '',
        notes: this.data.reminderNotes
      })
    }
  }

  saveReminder() {
    this.submitted = true;
    if (this.reminderForm.invalid) {
      return
    }
    this.dialogRef.close(this.reminderForm.value)
  }


}
