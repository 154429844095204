import { Injectable } from '@angular/core';
import { HttpMethod } from '../enums/http-handlers';
import { AppService } from './app.service';
import { CommonService } from './common.service';
import { SpinnerService } from './spinner.service';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminService {

  getUserAndJobStatusCodesData: any;
  getEmployerStatusesData: any;
  getEmployeeStatusesData: any;
  getBlogsMasterDataList: any;

  getActiveindustriesList = [];


  constructor(private appService: AppService,
    private commonService: CommonService,
    private spinnerService: SpinnerService) { }

  
  getUserAndJobStatusCodes(callBack) {
    if(this.getUserAndJobStatusCodesData) {
      callBack(true)
      return;
    }
    const apiObj = {
      url: `${this.appService.getUserAndJobStatusCodes}`,
      methodType: HttpMethod.GET
    }
    this.commonService.commonApiCall(apiObj, (res, statusFlag) => {
      this.spinnerService.hide();
      if (statusFlag && res && res.hasOwnProperty('data') && res.data) {
        this.getUserAndJobStatusCodesData = res.data;
        callBack(true)
      }
    })
  }


  getEmployerStatuses(callBack) {
    if(this.getEmployerStatusesData) {
      callBack(true)
      return;
    }
    const apiObj = {
      url: `${this.appService.getEmployerStatuses}`,
      methodType: HttpMethod.GET
    }
    this.commonService.commonApiCall(apiObj, (res, statusFlag) => {
      this.spinnerService.hide();
      if (statusFlag && res && res.hasOwnProperty('data') && res.data && res.data.hasOwnProperty('employerStatues') && res.data.employerStatues && res.data.employerStatues.length) {
        this.getEmployerStatusesData = res.data.employerStatues;
        callBack(true)
      }
    })
  }


  getEmployeeStatuses(callBack) {
    if(this.getEmployeeStatusesData) {
      callBack(true)
      return;
    }
    const apiObj = {
      url: `${this.appService.getEmployeeStatuses}`,
      methodType: HttpMethod.GET
    }
    this.commonService.commonApiCall(apiObj, (res, statusFlag) => {
      this.spinnerService.hide();
      if (statusFlag && res && res.hasOwnProperty('data') && res.data) {
        this.getEmployeeStatusesData = res.data;
        callBack(true)
      }
    })
  }

  getBlogsMasterData(callBack) {
    if(this.getBlogsMasterDataList) {
      callBack(true)
      return;
    }
    const url = `${this.appService.getBlogsMasterData}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET
    }
    this.commonService.commonApiCall(apiObj, ((res, statusFlag) => {
      this.spinnerService.hide();
      if (statusFlag && res && res.hasOwnProperty('data') && res.data) {
        this.getBlogsMasterDataList = res.data;
        callBack(true)
      }
    }));
  }

  
  getActiveindustries(callBack) {
    if(this.getActiveindustriesList && this.getActiveindustriesList.length) {
      callBack(true)
      return
    }
    const url = `${this.appService.getAllActiveIndustries}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
      showSpinner: false
    }
    this.commonService.commonApiCall(apiObj,(res, statusFlag) => {
      this.spinnerService.hide();
      if (statusFlag && res && res.hasOwnProperty('data') && res.data && res.data.hasOwnProperty('industries') && res.data.industries && res.data.industries.length) {
        this.getActiveindustriesList = res.data.industries;
        callBack(true)
      }
    });
  }

}
