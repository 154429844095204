<div class="footer">
    <div class="container">
        <div class="row footer-content">
            <div class="col-md-4 ">
                <div class="footer-logo">
                    <h3 class="logo">JOB Clicks <span>Logo</span></h3>
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                    <div class="row ">
                        <div class="col-md-6">
                            <h5>Contact us</h5>
                            <div class="social-icon">
                                <img src="assets/images/phone-call (3).png" alt=""><span>+1-202-555-0104</span> <br>
                                <img src="assets/images/mail (1).png" width="8px" height="8px" alt=""><span>example@gmail.com</span>
                            </div>
                        </div>
                        <div class="col-md-4  text-center">
                            <div class="follow">
                                <h5 class="">Follow us</h5>
                                <a href=""><img src="assets/images/facebook.png" alt=""></a>
                                <a href=""><img src="assets/images/twitter.png" alt=""></a>
                                <a href=""><img src="assets/images/instagram.png" alt=""></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 footer-menu">
                <h5>Information</h5>
                <a href="">About us</a>
                <a href="">Teams and Conditions</a>
                <a href="">Privacy Policy</a>
                <a href="">Contact us</a>
                <a href="">FAQs</a>
            </div>
            <div class="col-md-2 footer-menu">
                <h5>Browse Jobs</h5>
                <a href="">Jobs by Designation</a>
                <a href="">Jobs by Location</a>
                <a href="">Jobs by Skills</a>
            </div>
            <div class="col-md-2 footer-menu">
                <h5>Applicants</h5>
                <a href="">Register Now</a>
                <a href="">Login</a>
                <a href="">Search Job</a>
            </div>
            <div class="col-md-2 footer-menu">
                <h5>Employers</h5>
                <a href="">Job Posting</a>
                <a href="">Search Resumes</a>
                <a href="">Employer Login</a>
            </div>

        </div>
    </div>
    <div class="row footer-bar">
        <div class="col">
            <p class="text-center">Copyright © 1990 - 2021 Dice. All rights reserved. Use of this site is subject to certain Terms and Conditions</p>
        </div>
    </div>
</div>
